/* 

Sentra Template

https://templatemo.com/tm-518-sentra

*/

body {
  font-family: 'Open Sans', sans-serif;
}

p {
  font-size: 13px;
  color: #7a7a7a;
  line-height: 24px;
}

.page-content {
  width: 77.5%;
  float: right;
}

.content-section {
  padding-top: 80px;
  position: relative;
  width: 100%;
  display: inline-block;
  height: auto;
  left: 50%;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section-heading {
  text-align: left;
  position: relative;
  margin-bottom: 60px;
}

.section-heading h1 {
  margin-top: 0px;
  font-size: 32px;
  color: #232323;
  display: inline-block;
  margin-right: 50px;
}

.section-heading em {
  font-style: normal;
  font-weight: 700;
  color: #45489a;
}

.section-heading p {
  display: inline-block;
  font-size: 15px;
  line-height: 26px;
  color: #343434;
  position: absolute;
  top: 12.5px;
}

.white-button a {
  font-size: 13px;
  font-weight: 600;
  color: #45489a;
  text-transform: capitalize;
  background-color: #fff;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  padding: 0px 25px;
  letter-spacing: 0.25px;
  text-decoration: none;
  transition: all 0.3s;
}

.white-button a:hover {
  background-color: #45489a;
  color: #fff;
}

.accent-button a {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  background-color: #45489a;
  display: inline-block;
  height: 44px;
  line-height: 44px;
  padding: 0px 25px;
  letter-spacing: 0.25px;
  text-decoration: none;
  transition: all 0.3s;
}

.accent-button a:hover {
  background-color: #fff;
  color: #45489a;
}

header {
    position: fixed;
    top: 0;
    transition: top 0.3s ease-in-out;
    width: 100%;
}

.nav-up {
    top: -75px;
}

/* Responsive Navigation */

.responsive-nav {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 80px;
  background-color: rgba(250,250,250,.95);
  box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
}

.navbar-toggle {
  position: absolute;
  top: 40%;
  left: 50%;
  display: inline-block;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.navbar-nav {
  margin: 0;
  border: none;
}

#main-nav ul {
  text-align: center;
  background-color: rgba(0,0,0,0.9);
  margin-top: 59px;
  width: 100%;
}
#main-nav ul li {
  margin: 15px 0px;
}
#main-nav ul li:hover {
  background-color: transparent;
}
#main-nav ul li a {
  font-size: 15px;
  text-transform: capitalize;
  color: #fff;
  box-shadow: none;
  border: none;
}

#main-nav ul li a:hover {
  opacity: 0.5;
  background-color: transparent;
}

.navbar-toggle span {
  background-color: #232323;
}


/* Sidebar Style */

.sidebar-navigation {
/*  width: 22.5%;*/
  float: left;
  height: 100%;
  position: fixed;
  background-color: rgba(12,12,12,0.9);
  z-index: 10;
}

.sidebar-navigation .logo {
  position: absolute;
  width: 100%;
  height: 140px;
  line-height: 100px;
  background-color: #45489a;
  padding: 20px;
  text-align: center;
}

.sidebar-navigation .logo a {
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  height: 100px;
  width: 100%;
  display: inline-block;
  border: 1px solid rgba(250,250,250,0.5);
}

.sidebar-navigation .logo em {
  font-style: normal;
  font-weight: 300;
}

.sidebar-navigation nav {
  position: relative;
  line-height:.2em;
  top: 40%;
  left: 60%;
  -webkit-transform: translateX(-60%) translateY(-60%);
  -moz-transform: translateX(-60%) translateY(-60%);
  -ms-transform: translateX(-60%) translateY(-60%);
  -o-transform: translateX(-60%) translateY(-60%);
  transform: translateX(-60%) translateY(-60%);
}

.sidebar-navigation ul {
  margin-left: 45px;
  list-style: none;
  padding: 0;
}
.sidebar-navigation li{
  padding: 10px 0;
}
.sidebar-navigation span{
  display: inline-block;
  position:relative;
}
.sidebar-navigation nav a{
  display: inline-block;
  color: #fff;
  margin-top: 5px;
  text-decoration: none!important;
  font-size: 15px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.circle{
  margin-right: 5px;
  height: 10px;
  width: 10px;
  left: 0px;
  top: -1px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #fff;
  transition: all 0.3s;
}
.rect{
  height: 1px;
  width: 0px;
  left: 0;
  bottom: 5.5px;
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.1s, width 0.6s;
  -moz-transition: -webkit-transform 0.1s, width 0.6s;
  transition: transform 0.1s, width 0.6s;
}
.sidebar-navigation nav a:focus {
  color: #fff;
}
.sidebar-navigation nav a:hover, nav .active-section {
  color: #fff;
}
.sidebar-navigation nav a:hover span, nav .active-section span{
  background-color: #ddd;
}
.sidebar-navigation nav .active-section .rect{
  width: 30px;
}
.sidebar-navigation nav .active-section .circle{
  background-color: #fff;
}

.sidebar-navigation .social-icons {
  position: absolute;
  width: 100%;  
  bottom: 10px;
  padding: 0;
  margin: 0;
  text-align: center;
}

.sidebar-navigation .social-icons li {
  display: inline-block;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.sidebar-navigation .social-icons li:last-child {
  margin-right: 0px;
}

.sidebar-navigation .social-icons li a {
  font-size: 18px;
  color: #fff;
  transition: all 0.5s;
}

.sidebar-navigation .social-icons li a:hover {
  color: #45489a;
}


/* Slider Style */

.slider .content-section {
  padding-top: 0px;
  min-width: 100%;
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.slider p a {
	color: #FFF;
}
.slider p a:hover {
	color: #FF0;
	text-decoration: none;
}

.Modern-Slider .info {
  text-align: right;
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 60px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
}

.Modern-Slider .info h1 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 48px;
  color: #fff;
  font-weight: 700;
}

.Modern-Slider .info p {
  font-size: 15px;
  color: #fff;
}

.Modern-Slider .info .white-button {
  margin-top: 30px;
}

.Modern-Slider .info .white-button a:focus {
  outline: none;
}

.Modern-Slider .slick-dots {
  opacity: 0;
}

.Modern-Slider .NextArrow{
  outline: none;
  display: none;
  position:absolute;
  top:60px;
  right:60px;
  width:50px;
  height:50px;
  background:rgba(0,0,0,.50);
  border:0 none;
  margin-top:-22.5px;
  text-align:center;
  font:32px/50px FontAwesome;
  color:#FFF;
  z-index:5;
}

.Modern-Slider .NextArrow:before{content:'\f105';}

.Modern-Slider .PrevArrow{
  outline: none;
  position:absolute;
  top:60px;
  right: 120px;
  width:50px;
  height:50px;
  background:rgba(0,0,0,.50);
  border:0 none;
  margin-top:-22.5px;
  text-align:center;
  font:32px/50px FontAwesome;
  color:#FFF;
  z-index:5;
}

.Modern-Slider .item .image {
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  display: block;
}

.Modern-Slider .item-1 .image {
  background-image: url(/slide_1.jpg);
}

.Modern-Slider .item-2 .image {
  background-image: url(https://res.cloudinary.com/dgneqc8oc/image/upload/v1723614436/slide_2_casvpy.jpg);
}

.Modern-Slider .item-3 .image {
  background-image: url(/slide_3.jpg);
}

.Modern-Slider .PrevArrow:before{content:'\f104';}

.Modern-Slider .item .image {
  animation: zoomin 12s ease-in-out infinite alternate;
  -webkit-animation: zoomin 12s ease-in-out infinite alternate;
}

@keyframes zoomin {
    0% {-webkit-transform: scale(1);}
    100% {-webkit-transform: scale(1.2);}
}

.Modern-Slider {background:transparent;}

/* ==== Slick Slider Css Ruls === */
.slick-slider{position:relative;display:block;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}
.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0}
.slick-list:focus{outline:none}.slick-list.dragging{cursor:hand}
.slick-slider .slick-track,.slick-slider .slick-list{-webkit-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
.slick-track{position:relative;top:0;left:0;display:block}
.slick-track:before,.slick-track:after{display:table;content:''}.slick-track:after{clear:both}
.slick-loading .slick-track{visibility:hidden}
.slick-slide{display:none;float:left /* If RTL Make This Right */ ;height:100%;min-height:1px}
.slick-slide.dragging img{pointer-events:none}
.slick-initialized .slick-slide{display:block}
.slick-loading .slick-slide{visibility:hidden}
.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}


/* Featured Style */

#featured {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 80px;
  border-bottom: 1px solid #eee;
}

#featured .section-heading {
  margin-right: 20%;
}

#featured .content-section {
  display: inline-block;
  position: relative;
}

#featured .owl-dots {
  margin-top: 40px;
}

#featured .owl-dots .owl-dot span  {
  width: 12px;
  height: 12px;
  background-color: #cdcdcd!important;
  display: inline-block;
  border-radius: 50%;
  margin: 0 3px;
}

#featured .owl-dots .active span {
  background-color: #45489a!important;
}

#featured .owl-dots button {
  outline: none;
}

#featured .owl-nav {
  position: absolute;
  display: inline-block;
  top: -110px;
  right: 0;
}

#featured .owl-nav button:focus {
  outline: none;
}

#featured .owl-nav .owl-prev span {
  margin-right: 10px;
}

#featured .owl-nav .owl-next span, #featured .owl-nav .owl-prev span {
  outline: none;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 42px;
  background-color: #eee;
  color: #232323;
  font-size: 42px;
  transition: all 0.5s;
}

#featured .owl-nav span:hover {
  background-color: #45489a;
  color: #fff;
}

#featured .item {
  text-align: left;
  position: relative;
  z-index: 10;
}

#featured .item .image {
  position: relative;
}

#featured .item .image img {
  width: 100%;
  overflow: hidden;
}

#featured .item .image .featured-button a {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  background-color: rgba(69,72,154, 0.9);
  display: inline-block;
  height: 44px;
  line-height: 44px;
  width: 100%;
  letter-spacing: 0.25px;
  text-decoration: none;
  transition: all 0.5s;
  position: absolute;
  z-index: 9;
  bottom: -44px;
  opacity: 0;
}

#featured .item:hover .featured-button a {
  bottom: 0;
  opacity: 1;
}

#featured .item .text-content {
  padding-top: 20px;
  background-color: #fff;
  position: relative;
  z-index: 10;
}

#featured .item .text-content h4 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 21px;
  font-weight: 600;
  color: #232323;
  letter-spacing: 0.5px;
}

#featured .item .text-content span {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  color: #7a7a7a;
  letter-spacing: 0.5px;
  margin-bottom: 12.5px;
}



/* Projects Style */

#projects {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 50px;
}

#projects .masonry {
  position: relative;
  width: 100%;
}

#projects .masonry .item img {
  transition: all 1s;
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}

#projects .masonry .item img:hover {
  opacity: 0.5;
}



/* Video Presentation Style */

#video {
  text-align: center;
  background-color: #f4f4f4;
  padding: 100px 60px;
}

#video .section-heading {
  margin-bottom: 30px;
  text-align: center;
}

#video .section-heading h1 {
  display: block;
  margin: 0px;
}

#video .section-heading p {
  position: relative;
  margin: 0px;
}

#video .accent-button {
  margin-top: 30px;
}

.box-video{
  width: 100%;
  position: relative;
  margin-top: 30px;
  cursor: pointer;
  overflow: hidden;
}

.box-video .bg-video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
}

.box-video .video-container{
  position: relative;
  margin: 0;
  z-index: 1;
}

.box-video .bt-play {
  position: absolute;
  top:50%;
  left:50%;
  margin:-30px 0 0 -30px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background:#fff;
  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
  z-index:2;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}
.box-video .bt-play:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  margin: -12px 0 0 -6px;
  border: solid transparent;
  border-left-color: #000;
  border-width: 12px 20px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}
.box-video:hover .bt-play {
  transform: scale(1.1);
}

.box-video.open .bg-video{
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .6s .8s;
  transition: all .6s .8s;  
}
.box-video.open .video-container{
  opacity: 1;
  -webkit-transition: all .6s .8s;
  transition: all .6s .8s;
}




/* Blog Style */

#blog {
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 80px;
}

#blog .tabs {
  list-style: none;
  margin: 0px;
  padding: 0px;
  height: 90px;
  width: 100%;
  background-color: #eee;
  border-bottom: 10px solid #45489a;
}

#blog .tabs li {
  display: inline-block;
  text-align: center;
  width: 25%;
  float: left;
  margin: 0;
  padding: 0;
}

#blog .tabs a {
  display: block;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  color: #232323;
  font-size: 19px;
  line-height: 80px;
  font-weight: 600;
  transition: 0.5s;
}

#blog .tabs a:hover {
  color: #45489a;
}


#blog .tabs .active:hover {
  color: #fff;
}

#blog .tabs .active {
  background-color: #45489a;
  color: #fff;
}

.tabgroup {
  background-color: #f4f4f4;
  padding: 20px 30px;
}

.clearfix:after {
  content:"";
  display:table;
  clear:both;
}

#blog .tabgroup ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#blog .tabgroup ul li {
  display: inline-block;
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

#blog .tabgroup ul li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

#blog .tabgroup ul li img {
  float: left;
  margin-right: 30px;
  max-width: 100%;
  overflow: hidden;
}
#blog .tabgroup ul li .text-content {
  display: inline;
}

#blog .item .text-content h4 {
  padding-top: 7.5px;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 21px;
  font-weight: 600;
  color: #232323;
  letter-spacing: 0.5px;
}

#blog .item .text-content span {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  color: #7a7a7a;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

#blog .item .text-content .accent-button {
  margin-top: 25px;
}




/* Contact Style */

#contact {
  padding-top: 0px;
}

#contact-content {
  padding: 80px 60px;
}

#contact input {
  border-radius: 0px;
  padding-left: 15px;
  font-size: 13px;
  color: #aaa;
  background-color: #f4f4f4;
  border: 1px solid #eee;
  outline: none;
  box-shadow: none;
  line-height: 40px;
  height: 40px;
  width: 100%;
  margin-bottom: 25px;
}

#contact textarea {
  border-radius: 0px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 13px;
  color: #aaa;
  background-color: #f4f4f4;
  border: 1px solid #eee;
  outline: none;
  box-shadow: none;
  height: 200px;
  max-height: 250px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
}

#contact button {
  font-size: 13px;
  border-radius: 0px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  background-color: #45489a;
  display: inline-block;
  height: 44px;
  line-height: 24px;
  outline: none;
  box-shadow: none;
  width: 100%;
  letter-spacing: 0.25px;
  text-decoration: none;
  transition: all 0.3s;
}

#contact button:hover {
  color: #232323;
  background-color: #f4f4f4;
}



/* Footer Style */

.footer {
  background-color: rgba(12,12,12,0.9);
  height: 80px;
  line-height: 80px;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.footer p {
  margin-bottom: 0px;
  font-size: 13px;
  line-height: 80px;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.footer p a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}



/* Responsive Style */

@media (max-width: 992px) {

  .responsive-nav {
    display: none;
  }

  .sidebar-navigation .logo a {
    font-size: 18px;
  }

  .sidebar-navigation nav a {
    font-size: 13px;
  }

  .sidebar-navigation nav ul {
    margin-left: 30px;
  }

  .sidebar-navigation .social-icons li a {
    font-size: 15px;
  }

}


@media (max-width: 767px) {

  .responsive-nav {
    display: block;
    height: 60px;
  }

  .page-content {
    width: 100%;
  }

  .Modern-Slider .info {
    text-align: center;
    right: 0px;
    padding: 0px 30px;
  }

  .Modern-Slider .NextArrow{
    outline: none;
    display: none;
    position:absolute;
    top:120px;
    right: 50%;
    transform: translateX(50%);
    margin-right: -30px;
    width:50px;
    height:50px;
    background:rgba(0,0,0,.50);
    border:0 none;
    text-align:center;
    font:32px/50px FontAwesome;
    color:#FFF;
    z-index:5;
  }

  .Modern-Slider .NextArrow:before{content:'\f105';}

  .Modern-Slider .PrevArrow{
    outline: none;
    position:absolute;
    top:120px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -30px;
    width:50px;
    height:50px;
    background:rgba(0,0,0,.50);
    border:0 none;
    text-align:center;
    font:32px/50px FontAwesome;
    color:#FFF;
    z-index:5;
  }

  

}

@media (max-width: 690px) {

  .section-heading p {
    position: relative;
  }

  #featured {
    padding-left: 15px;
    padding-right: 15px;
  }

  #featured .owl-nav {
    position: absolute;
    display: inline-block;
    top: -220px;
    right: 0;
  }

  #projects {
    padding-left: 15px;
    padding-right: 15px;
  }

  #video {
    padding-left: 15px;
    padding-right: 15px;
  }

  #blog {
    padding-left: 15px;
    padding-right: 15px;
  }

  #blog .tabs a {
    font-size: 13px;
  }

  #blog .tabgroup ul li img {
    float: none;
    width: 100%;
    margin-bottom: 15px;
  }
  #blog .tabgroup ul li .text-content {
    display: block;
  }

  #contact-content {
    padding-left: 15px;
    padding-right: 15px;
  }

}