.item {
    position: relative;
    overflow: hidden;
    cursor: pointer; /* Set cursor to pointer */
}

.item img {
    transition: transform 0.3s ease;
}

.item:hover img {
    transform: scale(1.05);
}

.item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4); /* Lighter overlay color */
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.item:hover::before {
    opacity: 1;
}
.slider-container {
    position: relative;
    max-width: 800px;
    margin: auto;
    overflow: hidden;
}

.prev-slide, .next-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.prev-slide {
    left: 0;
}

.next-slide {
    right: 0;
}

.prev-slide:hover, .next-slide:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

