.banner-main-about {
    float: left;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 7px solid #a92446;
    min-height: 320px;
    background-color: white;
    background-position: bottom right;
}
.banner-main-about-text {
    position: relative;
    float: left;
    width: 55%;
    color: #000;
    text-align: left;
    right: 0;
    top: 0px;
    left: 0;
    text-shadow: none;
}
.banner-main-about {
    padding: 70px 30px 60px 50px;
}

.margin-b-30 {
    margin-bottom: 30px;
}
.banner-main-about img {
    float: right;
    margin: 45px 0px;
}

.about_box_line {
    max-width: 410px;
}

.about_box_line {
    border-top: 7px solid #10a5cd;
}
.box {
    float: left;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    display: block;
    background-color: #fff;
}

.box-icon {
    width: 100%;
    position: relative;
}
.info {
    width: 100%;
    padding: 15px 30px 20px;
}
.line {
    float: left;
    width: 100%;
    height: 1px;
    background-color: #ccc;
}
.about_box_line {
    max-width: 410px;
}
.about_box_line {
    border-top: 7px solid #10a5cd;
}
.box {
    float: left;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    display: block;
    background-color: #fff;
}